







































import { Component, Prop, Vue } from 'vue-property-decorator'

// components
import Player from '@/components/_uikit/Player.vue'
import { EducationLargeTaskResource, EducationLargeTaskSpeakingQuestionResource, MediaResource } from '@/store/types'

@Component({
  components: {
    Player,
  },
})
export default class mentorQuestionAudioQueue extends Vue {
  @Prop({ required: true })
  private question!: EducationLargeTaskSpeakingQuestionResource

  @Prop({ required: true })
  private questionIndex!: number

  @Prop({ required: true })
  private task!: EducationLargeTaskResource

  // Просмотр дз наставником в модалке
  @Prop({ default: false })
  private readonly!: boolean

  private get answersMedia() {
    const media: { [key: number]: MediaResource } = {}
    this.question.answers.map(question => {
      media[question.questionId] = question.media
    })
    return media
  }
}
