








import { Component, Prop, Vue } from 'vue-property-decorator'


@Component
export default class MentorRateTaskRow extends Vue {
  @Prop({ required: true })
  private value!: number

  @Prop({ default: null })
  private comment!: string

  @Prop({ default: true })
  private isShowComment!: boolean
}
