




























































import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { Debounce } from 'lodash-decorators'

// components
import TiptapEditor from '@/components/_uikit/editor/TiptapEditor.vue'
import MentorQuestionAudioQueue from '@/components/views/exercise/mentor/MentorQuestionAudioQueue.vue'
import MentorQuestionAudioSingle from '@/components/views/exercise/mentor/MentorQuestionAudioSingle.vue'
import MentorQuestionTextQueue from '@/components/views/exercise/mentor/MentorQuestionTextQueue.vue'
// store
import MentorExercisesModule from '@/store/modules/mentor/exercises'
import {
  EducationLargeTaskResource,
  EducationLargeTaskSpeakingQuestionResource,
  SpeakingQuestionType,
  EducationAnswerRateComment,
} from '@/store/types'

@Component({
  components: {
    TiptapEditor,
    MentorQuestionAudioQueue,
    MentorQuestionAudioSingle,
    MentorQuestionTextQueue,
  },
})
export default class MentorSpeakingQuestionsView extends Vue {
  @Prop({ required: true })
  private question!: EducationLargeTaskSpeakingQuestionResource

  @Prop({ required: true })
  private index!: number

  @Prop({ required: true })
  private task!: EducationLargeTaskResource

  @Prop({ default: () => ([]) })
  private mentorAnswerComments!: EducationAnswerRateComment[]

  @Prop({ default: false })
  private taskOnReview!: boolean

  // Просмотр дз наставником в модалке
  @Prop({ default: false })
  private readonly!: boolean

  private content = this.mentorCommentDraft?.mentorComment ?? this.question.rateComment ?? null

  private editorButtonsList = [
    'bold',
    'color',
    'marker',
    'align',
    'bulletList',
    'table',
    'blockquote',
    'link',
    'image',
    'formatClear',
  ]

  private get mediaCount() {
    return this.question.media.length
  }

  // Нечетное ли кол-во медиа
  private get isEvenMediaCount() {
    return Boolean(this.mediaCount % 2)
  }

  private get isQuestionAudioSingle() {
    return this.question.type === SpeakingQuestionType.AUDIO_SINGLE
  }

  private get isQuestionTextQueue() {
    return this.question.type === SpeakingQuestionType.TEXT_QUEUE
  }

  private get isQuestionAudioQueue() {
    return this.question.type === SpeakingQuestionType.AUDIO_QUEUE
  }

  private get mentorAnswerComment() {
    return {
      questionId: this.question.id,
      mentorComment: this.content,
    }
  }

  private get mentorCommentDraft() {
    return this.mentorAnswerComments?.find(comment => comment.questionId === this.question.id) ?? null
  }

  private handleEditorInput() {
    MentorExercisesModule.updateMentorRateComment(this.mentorAnswerComment)
  }

  @Debounce(2000)
  private updateContent() {
    this.content = this.mentorCommentDraft?.mentorComment ?? this.question.rateComment ?? null
  }

  @Watch('mentorAnswerComments')
  private watchMentorAnswerCommentContent() {
    this.updateContent()
  }
}
