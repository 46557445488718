





















import { Component, Mixins, Prop } from 'vue-property-decorator'

import CreativeTaskFile from '@/components/views/exercise/CreativeTaskFile.vue'
import MentorRateTaskForm from '@/components/views/exercise/mentor/MentorRateTaskForm.vue'
import TaskEmpty from '@/components/TaskEmpty.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import {
  CourseType,
  EducationLargeTaskResource,
  ExerciseStatus,
  MasterLargeResource,
} from '@/store/types'

@Component({
  components: {
    CreativeTaskFile,
    MentorRateTaskForm,
    TaskEmpty,
  },
})
export default class MentorCreativeTask extends Mixins(NotifyMixin) {
  @Prop({ required: true })
  private task!: EducationLargeTaskResource

  @Prop({ required: true })
  private groupId!: number

  @Prop({ default: null })
  private master!: MasterLargeResource

  @Prop({ default: null })
  private exerciseCourseType!: CourseType

  @Prop({ default: false })
  private readonly!: boolean

  private get isStatusComplete () {
    return this.task.status.value === ExerciseStatus.COMPLETE
  }

  private get isStatusWait () {
    return this.task.exerciseStatus?.value === ExerciseStatus.WAIT || this.task.exerciseStatus?.value === ExerciseStatus.IN_PROCESS
  }
}
