























































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { orderBy, uniqBy } from 'lodash'

import QuestionIndicator from '@/components/_uikit/QuestionIndicator.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import TestQuestionResultView from '@/components/views/exercise/TestQuestionResultView.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import TestQuestionResultMixin from '@/mixins/TestQuestionResultMixin'
import TextAreaInput from '@/components/_uikit/controls/TextAreaInput.vue'
import MasterExercisesModule from '@/store/modules/master/exercises'
import MentorExercisesModule from '@/store/modules/mentor/exercises'
import {
  CourseType,
  EducationLargeTaskFormResource,
  EducationLargeTaskResource,
  EducationLargeTaskTestQuestionResource,
  ExerciseStatus, MasterLargeResource,
  MistakeShortResource, TaskQuestionType,
  TaskRateType,
} from '@/store/types'
import TaskScore from '@/components/views/exercise/TaskScore.vue'
import Tag from '@/components/_uikit/Tag.vue'
import { compareMonths } from '@/utils/functions'
import AuthModule from '@/store/modules/auth'

enum Filters {
  ALL = 'all',
  RIGHT = 'right',
  WRONG = 'wrong',
  HAS_ERROR = 'has_errors',
}

enum StatusTest {
  COMPLETE = 'Тест пройден',
  MISTAKES = 'Работа над ошибками',
  RETEST = 'Перепрохождение',
}

@Component({
  components: {
    QuestionIndicator,
    Select,
    Tag,
    TaskScore,
    TestQuestionResultView,
    TextAreaInput,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class TestTry extends Mixins(NotifyMixin, TestQuestionResultMixin) {
  @Prop({ required: true })
  private groupId!: number

  @Prop({ required: true })
  private task!: EducationLargeTaskResource

  @Prop({ required: true })
  private form!: EducationLargeTaskFormResource

  @Prop({ default: null })
  private master!: MasterLargeResource

  @Prop({ default: null })
  private exerciseCourseType!: CourseType

  @Prop({ required: true })
  private index!: number

  @Prop({ default: 'master' })
  private view!: 'master'|'mentor'

  private open = false
  private test: EducationLargeTaskTestQuestionResource[] = []
  private filter = Filters.ALL

  private statusTest = StatusTest
  private formAnswer = {
    answer: '',
  }
  private mistakeQuestionID: number | null = null

  private get filtersList () {
    return [
      { name: 'Все ответы', value: Filters.ALL },
      { name: 'Правильно', value: Filters.RIGHT },
      { name: 'Неправильно', value: Filters.WRONG },
      { name: 'Есть ошибки', value: Filters.HAS_ERROR },
    ]
  }

  private get questions () {
    if (this.test.length) {
      switch (this.filter) {
      case Filters.ALL: return this.test
      case Filters.RIGHT: return this.test.filter((question: EducationLargeTaskTestQuestionResource) => question.ratePoints === question.points && question.type.value !== this.questionNoAnswer)
      case Filters.WRONG:
        if (this.isMistakesStarted) {
          return this.test.filter((question: EducationLargeTaskTestQuestionResource) => (question.points && question.type.value !== this.questionNoAnswer && question.ratePoints === 0 || question.ratePoints > 0 && question.ratePoints !== question.points))
        } else {
          const wrong: EducationLargeTaskTestQuestionResource[] = []
          this.test.forEach((question: EducationLargeTaskTestQuestionResource) => {
            if (question.ratePoints === 0 && question.type.value !== this.questionNoAnswer) {
              wrong.push(question)
              if (question.relatedUuid) {
                const find = this.test.find(q => q.uuid === question.relatedUuid)
                if (find)
                  wrong.push(find)
              }
            }
          })
          return orderBy(uniqBy(wrong, 'id'), 'position')
        }
      case Filters.HAS_ERROR: return this.test.filter((question: EducationLargeTaskTestQuestionResource) => question.ratePoints > 0 && question.ratePoints !== question.points)
      }
    }

    return []
  }

  private get questionsWithoutNoAnswer () {
    return this.questions.filter((item: EducationLargeTaskTestQuestionResource) => item.type.value !== this.questionNoAnswer)
  }

  private get questionMistake() {
    return this.questions.find((question: EducationLargeTaskTestQuestionResource) => question.id === this.currentQuestion?.questionId)
  }

  private get questionMistakeRelated() {
    if (this.questionMistake && this.questionMistake.relatedUuid) {
      return this.test.find(q => q.uuid === this.questionMistake?.relatedUuid)
    }
    return null
  }

  private get currentQuestion() {
    return MasterExercisesModule.currentMistake
  }

  private get isMistakesStarted() {
    return MasterExercisesModule.isMistakesStarted
  }

  private get mistakesList() {
    return MasterExercisesModule.mistakes
  }

  private get completeStatus() {
    return this.task.status.value === ExerciseStatus.COMPLETE
  }

  // Актуален ли месяц выдачи дз
  private get checkActualMonth() {
    return compareMonths(this.spendingAt as string)
  }

  private get spendingAt() {
    return MasterExercisesModule.exercise?.spendingAt
  }

  // Для мастера
  private get completedMistakes() {
    return this.mistakesList.filter(mistake => mistake.completed).length
  }

  // Для наставника
  private get isCompletedMistakes() {
    return MentorExercisesModule.mistakes.every(mistake => mistake.completed)
  }

  private get mistakes() {
    const mistakes: { [key: number]: MistakeShortResource } = {}
    MasterExercisesModule.mistakes.forEach(mistake => {
      mistakes[mistake.questionId] = {
        ...mistake,
      }
    })
    return mistakes
  }

  // РНО мастера в проверке дз у наставника
  private get masterMistakes() {
    const mistakes: { [key: number]: MistakeShortResource } = {}
    MentorExercisesModule.mistakes.forEach(mistake => {
      mistakes[mistake.questionId] = {
        ...mistake,
      }
    })
    return mistakes
  }

  private get questionNoAnswer() {
    return TaskQuestionType.NO_ANSWER
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  // РНО доступно, если:
  // РНО не начато и
  // РНО пройдено не полностью и
  // проверка дз не завершена или завершена, но дедлайн в прошедшем месяце и с бэка пришел флаг is_work_on_mistakes_required === true
  private get isAllowMistakes() {
    return !this.isMistakesStarted && this.mistakesList.length > this.completedMistakes && (!this.completeStatus || this.completeStatus && !this.checkActualMonth && this.task.forms[0].isWorkOnMistakesRequired)
  }

  private mounted() {
    this.$bus.$on('startMistakes', this.startMistakes)
    this.$bus.$on('endMistakes', this.endMistakes)
    this.$bus.$on('saveMistake', this.saveMistake)
  }

  private destroyed() {
    this.$bus.$off('startMistakes', this.startMistakes as any)
    this.$bus.$off('endMistakes', this.endMistakes as any)
    this.$bus.$off('saveMistake', this.saveMistake as any)
  }

  private startMistakes() {
    this.mistakeQuestionID = MasterExercisesModule.mistakes.find(question => !question.completed)?.questionId || MasterExercisesModule.mistakes[0].questionId
    MasterExercisesModule.setStartMistakes()
    this.open = true
    this.filter = Filters.WRONG
  }

  private endMistakes(save = true) {
    // Проверить перед отменой РНО, введно ли что-то в инпут
    // если да, то сохранять
    if (this.formAnswer.answer && this.formAnswer.answer.trim() && this.currentQuestion && !this.currentQuestion.completed && save) {
      MasterExercisesModule.saveMistake({
        body: { completed: false, correction: this.formAnswer.answer },
        formId: this.currentQuestion.formId,
        masterGroupID: this.groupId,
        questionId: this.currentQuestion.questionId,
      })
        .then(() => {
          this.notifySuccess('Введённый ответ сохранен, потом его можно будет изменить')
        })
        .catch(this.notifyError)
    }
    MasterExercisesModule.setEndMistakes()
    MasterExercisesModule.unsetCurrentMistake()
    this.filter = Filters.ALL
    this.mistakeQuestionID = null
  }

  private changeCurrentQuestion(id: number, save = true) {
    // Проверить перед переключением на другой вопрос РНО, введено ли что-то в инпут
    // если да, то сохранять
    if (this.formAnswer.answer && this.formAnswer.answer.trim() && this.currentQuestion && !this.currentQuestion.completed && save) {
      MasterExercisesModule.saveMistake({
        body: { completed: false, correction: this.formAnswer.answer },
        formId: this.currentQuestion.formId,
        masterGroupID: this.groupId,
        questionId: this.currentQuestion.questionId,
      })
        .then(() => {
          this.notifySuccess('Введённый ответ сохранен, потом его можно будет изменить')
          const form: any = this.$refs.formAnswer
          if (form) {
            form.reset()
          }
        })
        .catch(this.notifyError)
    }
    this.mistakeQuestionID = id
  }

  private saveMistake() {
    const form: any = this.$refs.formAnswer

    if (form)
      form.validate()
        .then(async (result: boolean) => {
          if (result && this.currentQuestion) {
            MasterExercisesModule.saveMistake({
              body: { completed: true, correction: this.formAnswer.answer },
              formId: this.currentQuestion.formId,
              masterGroupID: this.groupId,
              questionId: this.currentQuestion.questionId,
            })
              .then(response => {
                if (response.finished) {
                  this.endMistakes(false)
                  this.notifySuccess('Работа над ошибками выполнена')
                  MasterExercisesModule.fetchTaskMessages({
                    exerciseUUID: this.$route.params.exerciseUUID,
                    masterGroupID: this.groupId,
                    taskUUID: this.task.uuid,
                  })
                    .then(() => {
                      this.$router.replace({
                        name: 'master.exercises.item.task.messages',
                        params: {
                          exerciseUUID: this.$route.params.exerciseUUID,
                          groupID: this.$route.params.groupID,
                          taskUUID: this.task.uuid,
                        },
                      })
                    })
                    .catch(this.notifyError)
                } else if (response.questionId) {
                  this.changeCurrentQuestion(response.questionId, false)
                }
                this.notifySuccess('Ответ сохранен')
                form.reset()
              })
              .catch(this.notifyError)
          } else {
            this.notifyError('Проверьте введенные данные')
          }
        })
  }

  private isQuestionWithoutRightAnswer (question: EducationLargeTaskTestQuestionResource) {
    return question.rateType.value === TaskRateType.NO_RIGHT
  }

  private getTryStatus (form: EducationLargeTaskFormResource, index: number) {
    if (index > 0) {
      return StatusTest.RETEST
    } else if (form.correct === form.questions && form.rate === this.task.maxPoints) {
      return StatusTest.COMPLETE
    } else {
      return StatusTest.MISTAKES
    }
  }

  private fetchTest () {
    if (!this.test.length) {
      if (this.view === 'master') {
        MasterExercisesModule.fetchExerciseTask({
          exerciseUUID: this.task.exerciseUuid,
          masterGroupID: this.groupId,
          params: { formId: this.form.id },
          taskUUID: this.task.uuid,
        })
          .then((response: EducationLargeTaskResource) => {
            if (response.test && response.test.questions && response.test.questions.length) {
              this.test = response.test.questions.map((question: EducationLargeTaskTestQuestionResource, index: number) => ({ ...question, number: index + 1 }))
            }
          })
          .catch(this.notifyError)
      } else {
        MentorExercisesModule.fetchMasterTask({
          masterGroupID: this.groupId,
          masterID: this.master.user.id,
          params: { formId: this.form.id },
          taskUUID: this.task.uuid,
        })
          .then((response: EducationLargeTaskResource) => {
            if (response.test && response.test.questions && response.test.questions.length) {
              this.test = response.test.questions.map((question: EducationLargeTaskTestQuestionResource, index: number) => ({ ...question, number: index + 1 }))
            }
          })
          .catch(this.notifyError)
      }
    }
  }

  private handleSummaryRowClick (id: number, uuid: string) {
    if (this.isMistakesStarted) this.changeCurrentQuestion(id)

    this.scrollToQuestion(`#q-${this.form.id}-${uuid}`)
  }

  private scrollToQuestion(selector: string) {
    this.$bus.$emit('scrollIntoView', selector)
  }

  @Watch('open')
  private watchOpen (value: boolean) {
    if (value) {
      this.fetchTest()
    } else if (!value && this.isMistakesStarted) {
      this.endMistakes()
    }
  }

  @Watch('mistakeQuestionID')
  private watchMistakeQuestionID(value: number | null) {
    if (this.task.test && this.task.forms.length && value)
      MasterExercisesModule.fetchMistake( { formID: this.task.forms[0].id, masterGroupID: this.groupId, questionID: value })
        .then(response => {
          this.formAnswer.answer = response.correction
          const questionUUID = this.task.test?.questions.find(question => question.id === value)?.uuid
          if (questionUUID) {
            this.scrollToQuestion(`#q-${this.form.id}-${questionUUID}`)
          }
        })
        .catch(this.notifyError)
  }
}
