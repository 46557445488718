





















































import { Component, Mixins, Prop, Ref } from 'vue-property-decorator'
import { Bind, Debounce } from 'lodash-decorators'

import Confirmation from '@/components/modals/Confirmation.vue'
import TestTry from '@/components/views/exercise/TestTry.vue'
import TaskEmpty from '@/components/TaskEmpty.vue'
import {
  EducationLargeTaskResource,
  CourseType,
  ExerciseStatus,
  NameValueResource,
  MasterLargeResource,
} from '@/store/types'
import ButtonTextIcon from '@/components/_uikit/buttons/ButtonTextIcon.vue'
import MentorExercisesModule from '@/store/modules/mentor/exercises'
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import MasterExercisesModule from '@/store/modules/master/exercises'
import { compareMonths } from '@/utils/functions'

@Component({
  components: {
    ButtonTextIcon,
    Confirmation,
    TaskEmpty,
    TestTry,
  },
})
export default class TestTries extends Mixins(NotifyMixin, PermissionsMixin) {
  @Ref() confirm!: Confirmation

  @Prop({ required: true })
  private task!: EducationLargeTaskResource

  @Prop({ required: true })
  private subject!: NameValueResource

  @Prop({ required: true })
  private groupId!: number

  @Prop({ default: null })
  private master!: MasterLargeResource

  @Prop({ default: null })
  private exerciseCourseType!: CourseType

  @Prop({ default: 'master' })
  private view!: 'master'|'mentor'

  private get groupID () {
    return +this.$route.params.groupID
  }

  private get exerciseUUID () {
    return this.$route.params.exerciseUUID
  }

  private get masterID () {
    return +this.$route.params.masterID
  }

  private get isStatusChecked() {
    return this.task.status.value === ExerciseStatus.CHECKED && !this.isStatusWait
  }

  private get isStatusComplete() {
    return this.task.status.value === ExerciseStatus.COMPLETE
  }

  private get isStatusOnCheck() {
    return this.task.status.value === ExerciseStatus.ON_CHECK && !this.isStatusWait
  }

  private get isStatusWait () {
    if (this.view === 'mentor') {
      return this.task.exerciseStatus?.value === ExerciseStatus.WAIT || this.task.exerciseStatus?.value === ExerciseStatus.IN_PROCESS
    }
    return this.task.status.value === ExerciseStatus.WAIT || this.task.status.value === ExerciseStatus.IN_PROCESS
  }

  private get isMistakesStarted() {
    return MasterExercisesModule.isMistakesStarted
  }

  private get isNeedMistakes() {
    return this.task.forms[0] ? this.task.forms[0].isWorkOnMistakesRequired : false
  }

  // Актуален ли месяц выдачи дз
  private get checkActualMonth() {
    return compareMonths(this.spendingAt as string)
  }

  private get spendingAt() {
    return MasterExercisesModule.exercise?.spendingAt
  }

  private get currentQuestion() {
    return MasterExercisesModule.currentMistake
  }

  private get mistakes() {
    return MasterExercisesModule.mistakes
  }

  private get completedMistakes() {
    return this.mistakes.filter(mistake => mistake.completed).length
  }

  // Note: это нужно в первую очередь для того, чтобы мы могли безболезненно разделить уже существующий функционал между
  // МГ и SpecialGroup
  private get isSpecialGroup() {
    return this.exerciseCourseType === CourseType.SPECIAL
  }

  private get isCompletedMistakes() {
    return MentorExercisesModule.mistakes.every(mistake => mistake.completed)
  }

  private cancelCheck() {
    MentorExercisesModule.removeExerciseFromWork({
      exerciseUuid: this.exerciseUUID,
      masterGroupId: this.groupID,
      masterId: this.masterID,
    })
      .then(() => {
        this.notifyInfo('Проверка дз отменена')
        this.$router.push({
          name: 'manager.education.exercises.quickStart',
        })
      })
      .catch(this.notifyError)
  }

  private confirmClose () {
    this.confirm.open(
      'Завершение проверки',
      !this.isCompletedMistakes ? 'Вы уверены, что хотите завершить проверку домашнего задания? Мастер еще не выполнил работу над ошибками' : 'Вы уверены, что хотите завершить проверку домашнего задания?',
      {
        buttonConfirmText: 'Завершить',
      },
    )
      .then(this.handleClose)
      .catch(() => {return})
  }

  @Debounce(300)
  @Bind
  private handleClose () {
    MentorExercisesModule.completeMasterTask({
      masterGroupID: this.groupId,
      masterID: this.master.user.id,
      taskUUID: this.task.uuid,
    })
      .then(() => {
        this.notifySuccess('Проверка завершена')
        this.$bus.$emit('close-master-task', false)
        this.$bus.$emit('rate-master-task', false)
      })
      .catch(this.notifyError)
  }
}
