









import { Component, Prop, Vue } from 'vue-property-decorator'

import { EducationLargeTaskResultResource } from '@/store/types'
import AuthModule from '@/store/modules/auth'

@Component
export default class CreativeTaskFile extends Vue {
  @Prop({ required: true })
  private file!: EducationLargeTaskResultResource

  @Prop({ default: false })
  private isMentor!: boolean

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }
}
