







import { Component, Prop } from 'vue-property-decorator'

import Vue from '@/utils/plugins/websockets'
@Component
export default class TaskScore extends Vue {
  @Prop({ default: false })
  private filled!: boolean

  @Prop({ required: true })
  private score!: number

  @Prop({ default: null })
  private maxScore!: number

  @Prop({ default: false })
  private lg!: boolean
}
