


















































import { Component, Prop, Mixins } from 'vue-property-decorator'

import CardHint from '@/components/_uikit/CardHint.vue'
import MentorCreativeTask from '@/components/views/exercise/mentor/MentorCreativeTask.vue'
import MentorPracticeTask from '@/components/views/exercise/mentor/MentorPracticeTask.vue'
import MentorRateTaskRow from '@/components/views/exercise/mentor/MentorRateTaskRow.vue'
import MentorSpeakingTask from '@/components/views/exercise/mentor/MentorSpeakingTask.vue'
import TestTries from '@/components/views/exercise/TestTries.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import {
  CourseType,
  EducationLargeTaskResource,
  ExerciseShortResource, ExerciseStatus, MasterLargeResource, Permission, TaskType,
} from '@/store/types'
import MentorExercisesModule from '@/store/modules/mentor/exercises'

@Component({
  components: {
    CardHint,
    MentorCreativeTask,
    MentorPracticeTask,
    MentorRateTaskRow,
    MentorSpeakingTask,
    TestTries,
  },
})
export default class ExercisesItemTaskMasterAnswer extends Mixins(NotifyMixin, PermissionsMixin) {
  @Prop({ required: true })
  private groupId!: number

  @Prop({ required: true })
  private exercise!: ExerciseShortResource

  @Prop({ required: true })
  private task!: EducationLargeTaskResource

  @Prop({ required: true })
  private master!: MasterLargeResource

  private get isCreative () {
    return this.task.type.value === TaskType.CREATIVE
  }

  private get isPractice () {
    return this.task.type.value === TaskType.PRACTICE
  }

  private get isTest () {
    return this.task.type.value === TaskType.TEST
  }

  private get isSpeaking () {
    return this.task.type.value === TaskType.SPEAKING
  }

  private get isStatusChecked () {
    return this.task.status.value === ExerciseStatus.CHECKED
  }

  private get isStatusComplete () {
    return this.task.status.value === ExerciseStatus.COMPLETE
  }

  private get isStatusOnCheck() {
    return this.task.status.value === ExerciseStatus.ON_CHECK
  }

  // Note: неверная типизация, пока будет только верная только для спецкурсов
  private get exerciseCourseType(): CourseType | null {
    if (MentorExercisesModule.exercise) {
      return MentorExercisesModule.exercise.course.type.value as CourseType
    }
    return null
  }

  private get isReadonly(): boolean {
    switch(this.exerciseCourseType) {
    case CourseType.SPECIAL:
      return !this.hasPermission(Permission.EDUCATION_SPECIAL_CHECK)
    case CourseType.DEFAULT:
      return !this.hasPermission(Permission.EDUCATION_EXERCISES_CHECK)
    default:
      return false
    }
  }

  private updateStatusTask(task: EducationLargeTaskResource) {
    MentorExercisesModule.setTask(task)
  }
}
