













import { Component, Prop, Vue } from 'vue-property-decorator'

// components
import Player from '@/components/_uikit/Player.vue'
// store
import { EducationLargeTaskResource, EducationLargeTaskSpeakingQuestionResource } from '@/store/types'

@Component({
  components: {
    Player,
  },
})
export default class MentorQuestionAudioSingle extends Vue {
  @Prop({ required: true })
  private question!: EducationLargeTaskSpeakingQuestionResource

  @Prop({ required: true })
  private questionIndex!: number

  @Prop({ required: true })
  private task!: EducationLargeTaskResource
}
